<template>
    <div style="position:relative;" class="custom-feature-container">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Empower Business with Efficient <span style="color:#00c8d8;">Job Scheduling Software </span></h1>
                    <p>                
                        <b>Are you tired of manually arranging your job schedules? Do you have trouble keeping track of your team's availability and making effective job scheduling? Well, look no further! With our job scheduling software assistance, you can revolutionise how you schedule jobs and streamline your business operations.  </b>
                        <br/><br/>
                        Managing shifts, arranging availability, and ensuring adequate coverage can be a time-consuming and challenging responsibility. However, the scheduling manager feature in <a href="https://recordtime.com.au/">Record TIME</a> aims to simplify job management and enhance productivity. Our job scheduling software reduces the number of clicks required to schedule jobs for employees while saving hours. Therefore, use our scheduling software to increase operational productivity, save time and accomplish more. Say goodbye to scheduling headaches and embrace an organised, effective strategy to manage your field staff.
                    </p>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/electrical-contractor-software')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
                    <v-btn color="#2e9fa9" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/pricing')" class="mr-7 btn-1" elevation="0">
                    View Pricing
                    </v-btn><br/><br/>
                </div>
            </div>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/job-scheduling.webp`" alt="efficient job scheduling to maximize productivity"  style="max-width: 100%;padding: 20px;">
            </div>
            <div style="clear: both;"></div>
        </div>
        
        <v-container><br/>
            <br/><br/>
            <div>
            <h2>Record TIME The Ultimate Solution for Effective Job Scheduling and Time Management</h2>
            <p>Identify the most important jobs that will contribute significantly to your objectives. Additionally, prioritise them based on urgency and impact. This approach allows you to focus on high-value activities and maximise productivity. Furthermore, this job scheduling software ensures that your time and energy are directed toward important things. This results in increased efficiency and goal achievement.</p>
            <div class="hidden-sm-and-down"><br/><br/></div>
            <div class="left-blog1">
                <img :src="`${publicPath}assets/features-image/assign-job-as-per-location.webp`" alt="job scheduling with record time is time saving" style="max-width: 80%;">
            </div>
            <div class="right-blog1">
                <h2>Take Charge of Your Field Service and Job Details Today</h2>
                <p>You can assign jobs based on location, availability, and skill set, ensuring that your employees are working to their full potential. Additionally, the software also enables you to store customer information, track job progress, and generate <a href="https://recordtime.com.au/custom-invoice-book">custom invoice book</a>, making your scheduling jobs process seamless. Furthermore,  you can create digital forms and docket templates for your 
                    <a href="https://recordtime.com.au/pre-start-checklist">pre start checklist</a> and proof of delivery records. In addition, this job scheduling software also helps with employee time tracking to enhance payroll management.</p>
            <br/><br/>  
            <h2>Don't waste time and let ineffective scheduling software hold you back. Use Record TIME and experience the difference in how well you can manage your field teams and projects in real time. Sign up today to optimise your job scheduling processes.</h2>
                </div>
                <div style="clear:both;"></div>
            </div><br/><br/>
            <div class="gray-container">
                <h3>Benefits of Job Scheduling Tool</h3>

                <div style="display: inline-table;margin-top:30px" class="list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/calendar.webp`" alt="simple scheduling interface">
                            <h2>Simple Scheduling Interface</h2>
                            <p>You can easily create, update, and manage job schedules using your mobile device with our user-friendly interface.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/availability-tracking.webp`" alt="real time employee availability tracking">
                            <h2>Real-time Availability Tracking</h2>
                            <p>Easily check the availability of your team members, ensuring that jobs are assigned to the right person.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/task.webp`" alt="customizable task assignment">
                            <h2>Customisable Task Assignment</h2>
                            <p>Organise tasks according to priority, location, and skill sets to maximise team productivity.</p>
                        </div>
                    </div>
                </div>
               </div>
        </v-container>

    <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>
        </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .custom-feature-container{
        .text-section h1{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 10px !important;
            }
        }
        .banner-img{
            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 40px !important;
                height: auto !important;
                margin-top: 20px;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 20px !important;
            }
        }
    }
    .customH2{
        position: relative;
        display: inline-block;
        padding-right: 55px;
        margin-bottom: 15px;
        color: #00C8D8;
        img{
            position: absolute;
            right: 0px;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        text-align: center;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        img{
            margin: 0px auto 10px;
            display: block;
        }
        p{
            margin-top: 15px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: center;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 800px);
      padding-right: 15px;
    }
    .right-blog1{
      width: 800px;
      float:right;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Job Scheduling Software to Enhance Productivity for your Business',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Job scheduling software to manage jobs and projects in real time, generate custom invoice book and making scheduling jobs process seamless.'},
      {name: 'keyword', content: 'job scheduling software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/job-scheduling-software' }
    
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },
        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
